import TopBar from "../../components/TopBar";
import { useState } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Button,
  Typography,
} from "@mui/material";

import SyncIcon from "@mui/icons-material/Sync";
import { useAuth } from "../../contexts/auth";
import { useEffect } from "react";
import { getCidades } from "../../services/api";
import ChartPieRepairs from "../../components/ChartPieRepairs";
import ChartPieNewInstallations from "../../components/ChartPieNewInstallations";
import ChartPieReactivations from "../../components/ChartPieReactivations";
import ChartPieRecalls from "../../components/ChartPieRecalls";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";

export default function Dashboard() {
  const { user } = useAuth();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selectedCity, setSelectedCity] = useState(0);
  const [cities, setCities] = useState([]);

  const DashTitle = ({ text }) => {
    return (
      <Typography
        variant="h4"
        sx={{
          color: `${colors.primary[300]}`,
          fontWeight: 600,
          marginBottom: "20px",
        }}
      >
        {text}
      </Typography>
    );
  };

  const Container = ({ children }) => {
    return (
      <Box
        sx={{
          backgroundColor: `${colors.background[600]}`,
          padding: "20px",
          marginRight: "20px",
          marginTop: "20px",
        }}
      >
        {children}
      </Box>
    );
  };

  useEffect(() => {
    getCidades(user.token)
      .then((res) => {
        res.data.unshift({ codcidade: "", codestado: 6, cidade: "None" });
        setCities(res.data);
      })
      .catch((err) => console.log(err));
  }, [selectedCity]);

  const handleChange = (event) => {
    setSelectedCity(event.target.value);
  };
  return (
    <>
      <TopBar pageTitle="Dashboard" />

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          margin: "10px 20px",
        }}
      >
        <FormControl sx={{ m: 1, minWidth: 180 }}>
          <InputLabel id="demo-simple-select-autowidth-label">
            Selecione a Cidade
          </InputLabel>
          <Select
            labelId="demo-simple-select-autowidth-label"
            id="demo-simple-select-autowidth"
            value={selectedCity}
            onChange={handleChange}
            autoWidth
            label="Selecione a Cidade"
          >
            {cities.map((city) => {
              return (
                <MenuItem
                  key={city.codcidade === "" ? 0 : city.codcidade}
                  value={city.codcidade}
                >
                  {city.cidade}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl>
          <Button
            variant="contained"
            color="primary"
            sx={{ height: "50px" }}
            onClick={() => setSelectedCity(selectedCity)}
          >
            <SyncIcon />
          </Button>
        </FormControl>
      </Box>

      {/* GRID */}
      {selectedCity ? (
        <Box sx={{ width: 1 }} p="20px">
          <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
            <Box
              sx={{
                backgroundColor: `${colors.background[600]}`,
                padding: "20px",
              }}
              gridColumn="span 6"
            >
              <DashTitle text="Suportes Pendentes" />
              <ChartPieRepairs city={selectedCity} />
            </Box>


            <Box
              sx={{
                backgroundColor: `${colors.background[600]}`,
                padding: "20px",
              }}
              gridColumn="span 6"
            >
              <DashTitle text="Instalações Novas" />
              <ChartPieNewInstallations city={selectedCity} />
            </Box>


            <Box
              sx={{
                backgroundColor: `${colors.background[600]}`,
                padding: "20px",
              }}
              gridColumn="span 6"
            >
              <DashTitle text="A.E - Reativações e Migrações" />
              <ChartPieReactivations city={selectedCity} />
            </Box>

            <Box
              sx={{
                backgroundColor: `${colors.background[600]}`,
                padding: "20px",
              }}
              gridColumn="span 6"
            >
              <DashTitle text="Recolhimentos Rádio" />
              <ChartPieRecalls city={selectedCity} />
            </Box>

          </Box>
        </Box>
      ) : undefined}

      {/* {selectedCity ? (
        <>
          <Box
            sx={{
              display: "flex",
              marginLeft: "20px",
            }}
          >
            <Container>
              <DashTitle text="Instalações Novas" />
            </Container>

            <Container>
              <DashTitle text="Instalações Novas" />
            </Container>
          </Box>

          <Box
            sx={{
              display: "flex",
              marginLeft: "20px",
            }}
          >
            <Container>
              <DashTitle text="Suportes" />
              <ChartPie city={selectedCity} />
            </Container>

            <Container>
              <DashTitle text="Instalações Novas" />
              <ChartPie city={selectedCity} />
            </Container>
          </Box>
        </>
      ) : undefined} */}
    </>
  );
}

