import { Formik } from "formik";
import { useAuth } from "../../contexts/auth";
import { Navigate } from "react-router-dom";
import { Box, Typography, TextField, Button, Alert } from "@mui/material";
import { useTheme } from "@emotion/react";
import { tokens } from "./../../theme";
import { doLogin } from "./../../services/api";
import { useMediaQuery } from "@mui/material";
import { useState } from "react";

export default function Login() {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [errorMessage, setErrorMessage] = useState("");
  const { user, login } = useAuth();

  if (user) {
    return <Navigate to="/app/dashboard" />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "500px",
          padding: "40px",
          borderRadius: "10px",
          backgroundColor: colors.background[600],
        }}
      >
        <Box>
          <Typography variant="h1" fontWeight="bold" textAlign="center">Pendências</Typography>
          <Typography mt="10px" variant="h5" color={colors.primary[400]} textAlign="center">Entre com suas credenciais para acessar o sistema</Typography>
        </Box>
        <Formik
          initialValues={{ email: "", password: "" }}
          validate={(values) => {
            const errors = {};

            if (!values.email) {
              errors.email = "Informe um e-mail válido";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "Informe um e-mail válido";
            }
            if (!values.password) {
              errors.password = "Informe uma senha";
            }

            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            doLogin(values) //api post data
              .then((res) => {
                if(res.data) login(res.data); //auth context
                else setErrorMessage(res.message);
              })
              .catch((err) => {
                setErrorMessage(err);
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(1, minmax(0, 1fr))"
                mt="20px"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
              >
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="E-mail"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  name="email"
                  error={!!touched.email && !!errors.email}
                  helperText={touched.email && errors.email}
                  sx={{ gridColumn: "span 1" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="password"
                  label="Senha"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                  name="password"
                  error={!!touched.password && !!errors.password}
                  helperText={touched.password && errors.password}
                  sx={{ gridColumn: "span 1" }}
                />
              </Box>
              
              <Box m="20px 0px">
                {errorMessage === "" ? undefined : <Alert severity="error">{errorMessage}</Alert>}
              </Box>

              <Box display="flex" justifyContent="end" mt="10px">
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  sx={{
                    padding: "20px"
                  }}
                  
                >
                  Efetuar Login
                </Button>
              </Box>
              {/* <Box display="flex" justifyContent="center" mt="10px">
                <Button
                  fullWidth
                  variant="text"
                  onClick={() =>
                    navigate("/registro-usuario", { replace: false })
                  }
                  sx={{
                    padding: "10px",
                    color: `${colors.primary[300]}`
                  }}
                >
                  Não tenho cadastro
                </Button>
              </Box> */}
            </form>
          )}
        </Formik>
      </Box>
    </Box>
  );
}
