import { useTheme } from "@emotion/react";
import { tokens } from "../theme";
import { Box, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { getMkosByLike} from "../services/api";
import { useAuth } from "../contexts/auth";
import TopBar from "./TopBar";

export default function ListOsbyLike({ query, title }) {
  const { user } = useAuth();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [osList, setOsList] = useState([]);
  const [loading, setLoading] = useState(true);

  async function fetchOsList() {
    await getMkosByLike(query, user.token)
      .then((res) => {
        setOsList(res.data);
        setLoading(false);
      })
      .catch((err) => alert(err.code));
  }

  useEffect(() => {
    fetchOsList();
    //eslint-disable-next-line
  }, [query]);

  const columns = [
    { field: "id", headerName: "COD OS" },
    { field: "dt_abertura", headerName: "ABERTO EM", filterable: false },
    {
      field: "dias_em_aberto",
      headerName: "DIAS",
      filterable: false,
      renderCell: ({ row: { dias_em_aberto } }) => {
        return (
          <Box>
            <Typography
              color={dias_em_aberto >= 3 ? colors.redAccent[400] : undefined}
            >
              {dias_em_aberto}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "usr_login",
      headerName: "LOGIN",
      cellClassName: "cell-accent",
      valueGetter: ({ row }) => {
        if (row.usr_login != null) {
          const login = row.usr_login.split(".")[0];
          return login;
        }
      },
    },
    {
      field: "nome_razaosocial",
      headerName: "CLIENTE",
      flex: 1,
    },
    { field: "cidade", headerName: "CIDADE" },
    { field: "bairro", headerName: "BAIRRO", flex: 0.5 },
    { field: "logradouro", headerName: "ENDEREÇO", flex: 0.5 },
    { field: "num_endereco", headerName: "NUM.", filterable: false },
    {
      field: "defeito_reclamado",
      headerName: "DEFEITO",
      flex: 1,
      filterable: false,
    },
  ];

  return (
    <>
      <TopBar pageTitle={title} />
      {/* Grid content */}
      <Box
        m="20px"
        sx={{
          backgroundColor: `${colors.background[600]}`,
          padding: "20px",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            color: `${colors.primary[400]}`,
            fontWeight: 600,
            marginBottom: "20px",
          }}
        >
          {osList.length} ordens de serviço contabilizadas
        </Typography>
        <Box
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .cell-accent": {
              color: colors.primary[400],
              fontWeight: "bold",
            },
          }}
        >
          <DataGrid
            loading={loading}
            rows={osList}
            columns={columns}
            getRowHeight={() => "auto"}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 20, page: 0 },
              },
            }}
            disableRowSelectionOnClick
            unstable_cellSelection
            experimentalFeatures={{ clipboardPaste: true }}
            unstable_ignoreValueFormatterDuringExport
            clipboardCopyCellDelimiter={","}
            unstable_splitClipboardPastedText={(text) =>
              text.split("\n").map((row) => row.split(","))
            }
            // getRowHeight={() => "auto"}
            // getEstimatedRowHeight={() => 10}
          />
        </Box>
      </Box>
    </>
  );
}
