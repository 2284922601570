import { Typography, Box, useTheme } from "@mui/material";
import { tokens } from "../theme";

export default function Header({ title, subtitle }){
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box display="flex" width="100%" justifyContent="space-between" alignItems="flex-start">
      <Box>
        <Typography
          variant="h3"
          color={colors.gray[200]}
          fontWeight="bold"
          sx={{ mb: "5px" }}
        >
          {title}
        </Typography>

        <Typography variant="h5" color={colors.primary[300]}>
          {subtitle}
        </Typography>
      </Box>
    </Box>
  );
};
