import { Box, Typography, IconButton, useTheme } from "@mui/material";
import { ColorModeContext, tokens } from "../theme";
import { useAuth } from "../contexts/auth";
import { useContext } from "react";

import LogoutIcon from "@mui/icons-material/Logout";
import ProfileLogo from "../assets/logo-infornet.jpg";
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';


export default function TopBar({ pageTitle }) {
  const { user, logout } = useAuth();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "10px 30px 10px 10px",
        backgroundColor: `${colors.primary[500]}`,
        color: "neutral.main",
      }}
    >
      {/* Page title */}
      <Typography variant="h3" alignContent="center" ml="20px">
        {pageTitle}
      </Typography>

      <Box
        sx={{
          display: "flex",
          alignItems: "center"
        }}
      >
        <Typography>light</Typography>
        {/* Toogle theme mode */}
        <IconButton onClick={colorMode.toggleColorMode}>
            {
            theme.palette.mode === "dark"
            ? <ToggleOnIcon fontSize="large" color="neutral" />
            : <ToggleOffIcon fontSize="large" color="neutral" />
            }
        </IconButton>
        <Typography mr="20px">dark</Typography>
        {/* Logout button */}
        
        <IconButton onClick={logout}>
          <LogoutIcon color="neutral" />
        </IconButton>

        {/* User profile */}
        <Box
          ml="20px"
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            src={ProfileLogo}
            alt="profile logo"
            width="48px"
            height="48px"
            style={{ cursor: "pointer", borderRadius: "50%" }}
          />

          {/* Go to profile page */}
          <Box ml="10px" alignItems="center">
            <Typography variant="h5" fontWeight="bold" lineHeight="0.8" sx={{cursor: "pointer"}}>
              {user.firstName} {user.lastName}
              <Typography>{user.email}</Typography>
            </Typography> 
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
