import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/auth";
import { useEffect } from "react";

export default function Home() {
  const {user} = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if(user){
      return navigate('/app/dashboard', {replace: true});
    }
    else{
      return navigate('/login', {replace: true});
    }
  });

  
}