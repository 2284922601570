import { Routes, Route } from "react-router-dom";

import Login from "./pages/login";
import Home from "./pages/home";
import NavMenu from "./components/NavMenu";
import Dashboard from "./pages/dashboard";
import ListOsByType from "./components/ListOsByType";
import ListOsbyLike from "./components/ListOsByLike";
import NoMatch from "./pages/nomatch";
import { ColorModeContext, useMode } from "./theme";
import { ThemeProvider } from "@emotion/react";
import { CssBaseline } from "@mui/material";
import SignIn from "./pages/signin";

export default function App() {
  const [theme, colorMode] = useMode();

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/registro-usuario" element={<SignIn />} />
          <Route path="*" element={<NoMatch />} />

          <Route path="/app" element={<NavMenu />}>
            <Route path="dashboard" element={<Dashboard />} />
            <Route
              path="instalacoes-novas"
              element={<ListOsbyLike query="INSTALA" title="Instalações Novas" />}
            />
            <Route
              path="migracoes"
              element={<ListOsbyLike query="MIGRA" title="Migrações de Tecnologia" />}
            />
            <Route
              path="reativacoes"
              element={<ListOsbyLike query="REATIVA" title="Reativações" />}
            />
            <Route
              path="alteracoes-endereco"
              element={
                <ListOsByType osType={23} title="Alterações de Endereço" />
              }
            />
            <Route
              path="vlans-offline"
              element={<ListOsByType osType={62} title="Vlans Offline" />}
            />
            <Route
              path="buscas-ativa"
              element={<ListOsByType osType={69} title="Buscas Ativas" />}
            />
            <Route
              path="viabilidades"
              element={<ListOsByType osType={40} title="Viabilidades" />}
            />
            <Route
              path="recolhimentos-radio"
              element={<ListOsByType osType={16} title="Recolhimentos Rádio" />}
            />
            <Route
              path="suportes-fisico"
              element={<ListOsByType osType={27} title="Suportes Físicos" />}
            />
            <Route
              path="suportes-rapido"
              element={<ListOsByType osType={10} title="Suportes Rápidos" />}
            />
            <Route path="*" element={<NoMatch />} />
          </Route>
        </Routes>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}
