import { useTheme } from "@emotion/react";
import { Pie } from "@nivo/pie";
import { tokens } from "../theme";
import { useState } from "react";
import { useEffect } from "react";
import { getCountReactivationsOs } from "../services/api";
import { useAuth } from "../contexts/auth";
import { Typography } from "@mui/material";

export default function ChartPie({ city }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { user } = useAuth();

  const [data, setData] = useState([]);

  useEffect(() => {
    getCountReactivationsOs(city, user.token)
      .then((res) => setData(res.data))
      .catch((err) => console.log(err.message));
  }, [city]);

  return (
    <>
      <Pie
        data={data}
        animate
        width={600}
        height={250}
        margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
        innerRadius={0.5}
        padAngle={0.7}
        activeOuterRadiusOffset={8}
        colors={{ scheme: "paired" }}
        arcLinkLabelsTextColor={colors.gray[200]}
        tooltip={({ datum: { id, value } }) => (
          <div
            style={{
              padding: "5px 10px",
              background: `${colors.background[600]}`,
              color: `${colors.gray[200]}`,
              borderRadius: "4px",
              border: `1px solid ${colors.gray[900]}`,
            }}
          >
            {id}: <strong>{value}</strong>
          </div>
        )}
        theme={{
          axis: {
            domain: {
              line: {
                stroke: colors.gray[100],
              },
            },
            legend: {
              text: {
                fill: colors.gray[100],
              },
            },
            ticks: {
              line: {
                stroke: colors.gray[100],
                strokeWidth: 1,
              },
              text: {
                fill: colors.gray[100],
              },
            },
          },
          legends: {
            text: {
              fill: colors.gray[100],
            },
          },
        }}
      />

      <Typography>
        Total: {data.reduce((soma, item) => soma + parseInt(item.value), 0)}
      </Typography>
    </>
  );
}
