import Axios from "axios";

const baseURL = "http://pendencias.ctechsolutions.com.br:3000";

export async function getMkosByType(type, token){
    return Axios.get(`${baseURL}/mk/os/type/${type}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => res.data)
      .catch((err) => err.response.data.msg);
}

export async function getMkosByLike(query, token){
  return Axios.get(`${baseURL}/mk/os/like/${query}`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((err) => err.response.data.msg);
}

export async function getCidades(token){
  return Axios.get(`${baseURL}/mk/os/cidades`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((err) => err.response.data.msg);
}

export async function getCountRepairOs(city, token){
  return Axios.get(`${baseURL}/mk/os/dashboard/suportes/${city}`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((err) => err.response.data.msg);
}

export async function getCountNewInstallationsOs(city, token){
  return Axios.get(`${baseURL}/mk/os/dashboard/instalacoes/${city}`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((err) => err.response.data.msg);
}

export async function getCountReactivationsOs(city, token){
  return Axios.get(`${baseURL}/mk/os/dashboard/reativacoes/${city}`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((err) => err.response.data.msg);
}

export async function getCountRecallsOs(city, token){
  return Axios.get(`${baseURL}/mk/os/dashboard/recolhimentos/${city}`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((err) => err.response.data.msg);
}


export async function doLogin(values){
    return Axios.post(`${baseURL}/auth/login`, values)
    .then((res) => res.data)
    .catch((err) => {
      return err.response.data;
    });
}

