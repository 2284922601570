import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Link, Navigate, useOutlet } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { useAuth } from "../contexts/auth";
import { useTheme } from "@emotion/react";
import { useState } from "react";
import { tokens } from "../theme";

/* ICONS IMPORT */
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import PriceCheckOutlinedIcon from "@mui/icons-material/PriceCheckOutlined";
import UpgradeOutlinedIcon from "@mui/icons-material/UpgradeOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";
import DnsOutlinedIcon from "@mui/icons-material/DnsOutlined";
import TaxiAlertOutlinedIcon from "@mui/icons-material/TaxiAlertOutlined";
import TwoWheelerOutlinedIcon from "@mui/icons-material/TwoWheelerOutlined";
import TravelExploreOutlinedIcon from "@mui/icons-material/TravelExploreOutlined";
import WifiFindOutlinedIcon from "@mui/icons-material/WifiFindOutlined";
import SettingsInputAntennaOutlinedIcon from "@mui/icons-material/SettingsInputAntennaOutlined";
import DashboardIcon from '@mui/icons-material/Dashboard';

export default function NavMenu() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selected, setSelected] = useState("Dashboard");
  const [isCollapsed, setIsCollapsed] = useState(false);

  const { user } = useAuth();
  const outlet = useOutlet();

  if (!user) {
    return <Navigate to="/login" />;
  }

  const Item = ({ title, icon, selected, setSelected, component }) => {
    return (
      <MenuItem
        active={selected === title}
        style={{
          color: colors.gray[200]
        }}
        onClick={() => setSelected(title)}
        icon={icon}
        component={component}
      >
        <Typography>{title}</Typography>
      </MenuItem>
    );  
  };

  return (
    <Box
      sx={{
        "& .ps-sidebar-root": {
          border: "0 !important",
        },
        "& .ps-sidebar-container": {
          background: `${colors.background[600]} !important`,
        },
        "& .ps-menuitem-root": {
          backgroundColor: "transparent !important",
        },
        "& .ps-menu-button:hover": {
          backgroundColor: `${colors.background[500]} !important`,
          color: `${colors.primary[400]} !important`,
        },
        "& .ps-active": {
          backgroundColor: `${colors.background[500]} !important`,
          color: `${colors.primary[400]} !important`,
        },
        "& .css-17w9904-MuiTypography-root": {
          fontWeight: 600
        },
        display: "flex",
        height: "100vh",
      }}
    >
      <Sidebar collapsed={isCollapsed}>
      <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.gray[100],
            }}
          >
            {!isCollapsed && (  
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant="h3" color={colors.gray[100]}>
                  PENDÊNCIAS
                </Typography>
                {/* <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton> */}
              </Box>
            )}
          </MenuItem>
        </Menu>

        <Menu>
          {/* MENU ITENS */}
          <Box padding={isCollapsed ? undefined : "10px"}>
            <Item
              title="Dashboard"
              component={<Link to="dashboard" />}
              icon={<DashboardIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Instalações Novas"
              component={<Link to="instalacoes-novas" />}
              icon={<PriceCheckOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Mig. Tecnologia"
              component={<Link to="migracoes" />}
              icon={<UpgradeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Alt. de Endereço"
              component={<Link to="alteracoes-endereco" />}
              icon={<LocalShippingOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Reativaçoes"
              component={<Link to="reativacoes" />}
              icon={<CachedOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Vlans Offline"
              component={<Link to="vlans-offline" />}
              icon={<DnsOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Suportes Físicos"
              component={<Link to="suportes-fisico" />}
              icon={<TaxiAlertOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Suportes Rápidos"
              component={<Link to="suportes-rapido" />}
              icon={<TwoWheelerOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Busca Ativa"
              component={<Link to="buscas-ativa" />}
              icon={<TravelExploreOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Viabilidades"
              component={<Link to="viabilidades" />}
              icon={<WifiFindOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="REC 1 - Rádio"
              component={<Link to="recolhimentos-radio" />}
              icon={<SettingsInputAntennaOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
          </Box>
        </Menu>
      </Sidebar>
      <Box width="100vw">{outlet}</Box>
    </Box>
  );
}
