import { Box, Button, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import TopBar from "../../components/TopBar";
import { tokens } from "../../theme";

export default function NoMatch() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  return (
    <>
      <TopBar pageTitle="404 - Not Found" />
      <Box
        m="20px"
        sx={{
          backgroundColor: `${colors.background[600]}`,
          padding: "20px",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            color: `${colors.primary[400]}`,
            fontWeight: 600,
            marginBottom: "20px",
          }}
        >
          404 - Not Found
        </Typography>
        <Typography variant="h4">
          Ops, essa página não foi encontrada
        </Typography>
        <Button
          type="button"
          variant="text"
          color="primary"
          size="large"
          onClick={() => navigate(-1)}
          sx={{
            marginTop: "30px",
            padding: "10px",
            color: `${colors.primary[300]}`
          }}
        >
          Voltar
        </Button>
      </Box>
    </>
  );
}
